
import { defineComponent, reactive, toRefs, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { useRequest } from "vue-request";
import SatIcon from "@/components/SatIcon.vue";
import SatCardCarousel from "@/components/carousel/SatCardCarousel.vue";
import {
  CalendarOutlined,
  FileProtectOutlined,
  ContactsOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons-vue";
import SatBreadcrumb from "@/components/SatBreadcrumb.vue";
import { stadium_type } from "@/_modules/mock";

import { athleteService } from "@/_services";
import {
  Athlete,
  BaseResponse,
  BreadcrumbRoute,
  IAthlete,
  IStadium,
  IStdiumType,
  IStaff,
} from "@/_modules/types";
import useStadiumRepositories from "@/_composables/useStadiumRepositories";
export default defineComponent({
  name: "Stadium",
  components: {
    SatIcon,
    SatCardCarousel,
    SatBreadcrumb,
    CalendarOutlined,
    FileProtectOutlined,
    ContactsOutlined,
    EnvironmentOutlined,
  },
  setup() {
    const { getStadium } = useStadiumRepositories();
    const { t } = useI18n({
      useScope: "global",
    });
    const store = useStore();
    const route = useRoute();
    watch(
      () => route.params.id,
      async (newId) => {
        if (Number.isNaN(+newId)) return;
        const result = await getStadium(+newId);
        // const banner = await getStadium(+newId, 'banner')
        if (result) {
          const { pitch, history, address } = result;
          state.stadium = { ...result };
          state.firstTab.info.map((ele) => {
            const { key } = ele;
            switch (key) {
              case "type":
                if (pitch.type !== "4") {
                  const type = stadium_type.find(
                    (elm) => elm.value === pitch.type
                  ) as IStdiumType;
                  ele.value = {
                    th: type.text.th,
                    en: type.text.en,
                  };
                }
                break;
              case "width":
                ele.value = {
                  th: pitch.width ? `${pitch.width} ม.` : "-",
                  en: pitch.width ? `${pitch.width} m.` : "-",
                };
                break;
              case "length":
                ele.value = {
                  th: pitch.length ? `${pitch.length} ม.` : "-",
                  en: pitch.length ? `${pitch.length} m.` : "-",
                };
                break;
            }
          });
          state.firstTab.content = history;
          state.firstTab.address = address;
          routes.value[1].breadcrumbName =
            store.state.currentLanguage === "th"
              ? state.stadium.name.th
              : state.stadium.name.en;
        }
        // await fetchAthlete(+newId)
      },
      { immediate: true }
    );
    const state = reactive({
      loading: true,
      stadium: {} as IStadium,
      athlete: {
        id: +route.params.id,
        age: 20,
        name: { en: "Chanathip Songkrasin", th: "ชนาธิป สรงกระสินธ์" },
        positions: [
          { LW: "ปีกซ้าย" },
          { CF: "กองหน้า" },
          { AMC: "กลางตัวรุก" },
        ],
        team: { club: { en: "", th: "" }, national: { en: "", th: "" } },
        nationallity: { en: "", th: "" },
        avatar: "",
        fitness: {
          percentage: 50,
          suggestion: {
            en: "",
            th: "",
          },
        },
        teamsId: [],
        birthday: "",
        nationality: "",
        weight: "",
        height: "",
      } as IAthlete,
      // tabs: [{ type: "ประวัติ", title: "ข้อมูลสนามกีฬา" }],
      tabs: {
        th: [{ type: "ประวัติ", title: "ข้อมูลสนามกีฬา" }],
        en: [{ type: "Record", title: "Stadium Information" }],
      },
      firstTab: {
        info: [
          {
            key: "type",
            title: {
              en: "Grass Type",
              th: "ชนิดสนาม",
            },
            icon: "SatIcon",
            props: { name: "grass", cssClass: "me-2 fs-5" },
            value: {
              en: "-",
              th: "-",
            },
          },
          {
            key: "width",
            title: {
              en: "Field Width",
              th: "ความกว้าง",
            },
            icon: "SatIcon",
            props: { name: "heightMan", cssClass: "me-2 fs-5" },
            value: {
              en: "-",
              th: "-",
            },
          },
          {
            key: "length",
            title: {
              en: "Field Length",
              th: "ความยาว",
            },
            icon: "SatIcon",
            props: { name: "weightMachine", cssClass: "me-2 fs-5" },
            value: {
              en: "-",
              th: "-",
            },
          },
        ],
        content: {
          en: "-",
          th: "-",
        },
        address: {
          en: "-",
          th: "-",
        },
      },
    });
    const routes = ref<BreadcrumbRoute[]>([
      {
        path: "stadiums",
        breadcrumbName: t("label.overview.stadium"),
      },
      {
        breadcrumbName: "-",
      },
    ]);
    return {
      t,
      store,
      ...toRefs(state),
      routes,
      activeKey: ref(0),
    };
  },
});
