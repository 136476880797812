import { ref, watch, Ref } from 'vue'
import { useRequest } from 'vue-request'
import {
  BaseResponse,
  Stadium,
  BasePagination,
  StadiumQueryDto,
  IStadium,
  SettingStadiumSocialContact
} from '@/_modules/types'
import { stadiumService } from '@/_services'
interface ReturnType {
  repositories: Ref<IStadium[]>;
  loading: Ref<boolean>;
  getStadiumRepositories: (q: StadiumQueryDto) => Promise<any | null>;
  getStadium: (id: number) => Promise<IStadium | null>;
}
export default function useStadiumRepositories(q?: Ref<StadiumQueryDto>): ReturnType {
  const repositories = ref<IStadium[]>([])
  const cb = (ele: Stadium) => {
    const { id, setting_stadium_owners, setting_stadium_regions: region, setting_stadium_name_th, setting_stadium_name_en, setting_stadium_capacity, setting_stadium_regions_name: regionName, pitch_details: pitch, setting_stadium_history_th, setting_stadium_history_en } = ele
    const { setting_stadium_owner_media_url: image, setting_stadium_owner_name_th: ownerName, setting_stadium_owner_name_en: ownerNameEn } = setting_stadium_owners
    const province = region?.setting_stadium_region_province_id?.province_name_th ?? regionName?.province_name_th ?? undefined
    const province_en = region?.setting_stadium_region_province_id?.province_name_en ?? regionName?.province_name_en ?? undefined

    const country = region?.setting_stadium_region_country_id?.country_native1_name_th ?? regionName?.country_name_th ?? undefined
    const country_en = region?.setting_stadium_region_country_id?.country_native1_name_en ?? regionName?.country_name_en ?? undefined

    const contact = ele.setting_stadium_social_contacts as SettingStadiumSocialContact


    return {
      id,
      name: {
        en: setting_stadium_name_en ?? '-',
        th: setting_stadium_name_th ?? '-'
      },
      country: {
        en: country_en ?? '-',
        th: country ?? '-'
      },
      image: image ?? '',
      province: {
        en: province_en ?? '-',
        th: province ?? '-'
      },
      capacity: +setting_stadium_capacity ?? 0,
      owner: {
        en: ownerNameEn ?? '-',
        th: ownerName ?? '-'
      },
      pitch: {
        width: pitch?.pitch_detail_width ?? '-',
        length: pitch?.pitch_detail_length ?? '-',
        type: pitch?.pitch_detail_grass ?? '4'
      },
      history: {
        th: setting_stadium_history_th ?? '-',
        en: setting_stadium_history_en ?? '-'
      },
      address: {
        th: regionName?.setting_stadium_region_address_detail ?? '' + ' ' + province ?? '' + ' ' + country ?? '',
        en: regionName?.setting_stadium_region_address_detail ?? '' + ' ' + province_en ?? '' + ' ' + country_en ?? ''
      },
      contact: {
        email: contact?.setting_stadium_social_contact_email || '-',
        tel: contact?.setting_stadium_social_contact_phone || '-',
        instagram: contact?.setting_stadium_social_contact_instagram || '-',
        line: contact?.setting_stadium_social_contact_line || '-',
        facebook: contact?.setting_stadium_social_contact_facebook || '-',
        twitter: contact?.setting_stadium_social_contact_twitter || '-',
        youtube: contact?.setting_stadium_social_contact_youtube || '-',
        website: contact?.setting_stadium_social_contact_website || '-'
      }
    } as IStadium
  }
  const formatResult = (result: any) => {
    const { data: stadium, ...others } = result?.stadium as any
    return {
      stadium: {
        ...others,
        data: stadium?.map(cb)
      }
    }
  }
  const { run: getStadiumRepositories, loading } = useRequest(
    stadiumService.list,
    {
      manual: true,
      formatResult,
      onSuccess: formattedData => {
        repositories.value = formattedData.stadium.data
      }
    }
  )

  const { run: getStadium } = useRequest(
    stadiumService.getById, {
      manual: true,
      formatResult: (result) => {
        return cb(result.data.stadium)
      }
    }
  )

  watch(() => q?.value, () => {
    if (q?.value) {
      getStadiumRepositories(q.value)
    }
  }, { immediate: true })

  return {
    repositories,
    loading,
    getStadiumRepositories,
    getStadium
  }
}
